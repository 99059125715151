import { GameDto } from '../dto/game.dto';
import PlayersStyle from './Players.module.css';
import Player from './Player';
import * as React from 'react';
import { Box, Divider } from '@mui/material';
import { toTimeString } from '../../utils/time';

type Props = { game: GameDto | undefined; gameUuid: string };

const Players: React.FunctionComponent<Props> = ({ game, gameUuid }) => {
  const [error, setError] = React.useState(false);
  const [currentGame, setCurrentGame] = React.useState<GameDto | undefined>(
    game,
  );

  React.useEffect(() => {
    if (error) {
      return;
    }
    fetch(`https://api.ph1lou.fr/games/players/${gameUuid}`, {
      mode: 'cors',
    })
      .then((response) => {
        if (response.status != 200) {
          throw new Error('Problem');
        }
        return response.json();
      })
      .then((game1: GameDto) => {
        setCurrentGame(game1);
      })
      .catch(() => {
        setError(true);
      });
  }, []);

  return (
    <div className={PlayersStyle.block}>
      {currentGame && (
        <Divider>
          <p className={PlayersStyle.title}>
            {currentGame.playersCount} Joueurs - Durée{' '}
            {toTimeString(currentGame.duration)}
          </p>
        </Divider>
      )}
      <Box sx={{ typography: 'body1' }}></Box>
      <div className={PlayersStyle.players}>
        {currentGame?.players?.map((player) => (
          <Player key={player.uuid} player={player} />
        ))}
      </div>
    </div>
  );
};

export default Players;
