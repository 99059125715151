import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import PlayerHeadStyle from './PlayerHead.module.css';

type Props = { uuid: string; name: string; head: string };

const PlayerHead: React.FunctionComponent<Props> = ({ uuid, name, head }) => {
  const navigate = useNavigate();
  return (
    <div
      className={PlayerHeadStyle.player_info}
      onClick={() => navigate(`/player-uuid/${uuid}`)}
    >
      <a className={PlayerHeadStyle.player_name} href="#">
        {name}
      </a>
      <img
        className={PlayerHeadStyle.head}
        src={`data:image/png;base64,${head}`}
        alt={name}
      />
    </div>
  );
};

export default PlayerHead;
