import { Divider, Pagination } from '@mui/material';
import { GameDto } from '../dto/game.dto';
import Detail from './Detail';
import DetailsStyle from './Details.module.css';
import * as React from 'react';

type Props = { game: GameDto | undefined; gameUuid: string };

const Details: React.FunctionComponent<Props> = ({ game, gameUuid }) => {
  const [pageCount, setPageCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [currentGame, setCurrentGame] = React.useState<GameDto | undefined>(
    game,
  );
  const itemsPerPage = 6;
  const [error, setError] = React.useState(false);
  const itemOffset =
    ((page - 1) * itemsPerPage) % (currentGame?.prettyEvents?.length || 0);

  React.useEffect(() => {
    fetch(`https://api.ph1lou.fr/games/events/${gameUuid}`, {
      mode: 'cors',
    })
      .then((response) => {
        if (response.status != 200) {
          throw Error('');
        }
        return response.json();
      })
      .then((game1: GameDto) => {
        setCurrentGame(game1);
        setPageCount(Math.ceil(game1.prettyEvents.length / itemsPerPage));
      })
      .catch(() => setError(true));
  }, []);

  return (
    <div className={DetailsStyle.events}>
      {currentGame && (
        <Divider>
          <p className={DetailsStyle.game_title}>
            {currentGame.name} - {currentGame.winnerCamp} -{' '}
            {new Date(currentGame.date).toLocaleString('fr-FR')}
          </p>
        </Divider>
      )}
      {pageCount > 0 && (
        <Pagination
          count={pageCount}
          onChange={(_event, page) => setPage(page)}
          className={DetailsStyle.pagination}
        />
      )}
      <div>
        {error ? (
          <p>Une Erreur s&apos;est produite</p>
        ) : (
          currentGame?.prettyEvents
            ?.slice(itemOffset, itemOffset + itemsPerPage)
            .map((event) => <Detail key={event.id} event={event} />)
        )}
      </div>
    </div>
  );
};

export default Details;
