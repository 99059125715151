import { useNavigate, useParams } from 'react-router-dom';
import { GameDto } from '../games/dto/game.dto';
import { PlayerDto } from '../games/dto/player.dto';
import PlayerView from './games/PlayerView';
import PlayerInfoStyle from './PlayerInfo.module.css';
import * as React from 'react';
import { Alert, Button, Divider, Pagination, TextField } from '@mui/material';
import PlayerHead from '../utils/PlayerHead';

const PlayerInfo: React.FunctionComponent<any> = () => {
  const params = useParams();
  const playerUuid = params.playerUuid;
  const [name, setName] = React.useState(params.name || '');
  const [player, setPlayer] = React.useState<PlayerDto>();
  const [error, setError] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [winRate, setWinRate] = React.useState(0);
  const itemsPerPage = 4;
  const navigate = useNavigate();
  const itemOffset = ((page - 1) * itemsPerPage) % (player?.games?.length || 0);

  React.useEffect(() => {
    if (player || error) {
      return;
    }
    if (!name && !playerUuid) {
      return;
    }
    fetch(
      `https://api.ph1lou.fr/games/${
        name ? `player-name/${name}` : `player-uuid/${playerUuid}`
      }`,
    )
      .then((value) => {
        if (value.status != 200) {
          throw new Error('Error when player');
        }
        return value.json();
      })
      .then((player: PlayerDto) => {
        setPlayer(player);
        setPageCount(Math.ceil(player.games.length / itemsPerPage));
        setWinRate(
          (player.games.filter((game: GameDto) => game.players[0].winner)
            .length /
            player.games.length) *
            100,
        );
        setName(player.name);
      })
      .catch(() => setError(true));
  }, [player, name, playerUuid, itemsPerPage, error]);

  return (
    <>
      <Divider>
        <p className={PlayerInfoStyle.player_title}>Rechercher joueur</p>
      </Divider>
      <div className={PlayerInfoStyle.search}>
        <TextField
          onChange={(event) => setName(event.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              navigate(`/player-name/${name}`);
              setPlayer(undefined);
              setError(false);
            }
          }}
          value={name}
          id="outlined-basic"
          label="Joueur"
          variant="outlined"
        />
        <Button
          onClick={() => {
            navigate(`/player-name/${name}`);
            setPlayer(undefined);
            setError(false);
          }}
        >
          Chercher
        </Button>
      </div>
      {error ? (
        <Alert severity="error" className={PlayerInfoStyle.alert_player}>
          Joueur Inconnu
        </Alert>
      ) : (
        player && (
          <>
            <div className={PlayerInfoStyle.player_details}>
              <div className={PlayerInfoStyle.player}>
                {player && (
                  <PlayerHead
                    uuid={player.uuid}
                    name={player.name}
                    head={player.head}
                  />
                )}
                <p>
                  Nombre de Partie(s) <b>{player ? player.games.length : 0}</b>
                </p>
                <p>
                  Taux de Victoires <b>{winRate.toFixed(2)}%</b>
                </p>
              </div>
              <Divider>
                <p className={PlayerInfoStyle.games_title}>Parties jouées</p>
              </Divider>
              <Pagination
                count={pageCount}
                onChange={(_event, page) => setPage(page)}
              />
              <div className={PlayerInfoStyle.games}>
                {player?.games
                  .slice(itemOffset, itemOffset + itemsPerPage)
                  .map((game) => (
                    <PlayerView key={game.gameUUID} game={game} />
                  ))}
              </div>
            </div>
          </>
        )
      )}
    </>
  );
};

export default PlayerInfo;
