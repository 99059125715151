import {
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Box,
} from '@mui/material';
import { toTimeString } from '../../utils/time';
import { PlayerInfoDto } from '../dto/player_info.dto';
import PlayerStyle from './Player.module.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as React from 'react';
import PlayerHead from '../../utils/PlayerHead';

type Props = { player: PlayerInfoDto };

const Player: React.FunctionComponent<Props> = ({ player }) => {
  let killerId = 1;
  let loverId = 1;
  return (
    <Box sx={{ minWidth: 275 }}>
      <Accordion className={PlayerStyle.player}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={PlayerStyle.player_summary}
        >
          <PlayerHead
            uuid={player.uuid}
            name={player.name}
            head={player.head}
          />
          <p>{player.roleTranslation}</p>
        </AccordionSummary>
        <AccordionDetails className={PlayerStyle.player_details}>
          {player.winner ? (
            <p>Victoire ♕</p>
          ) : (
            player.deathTime > 0 && (
              <p>Mort : {toTimeString(player.deathTime)}</p>
            )
          )}

          <p>{player.infected && 'Infecté'}</p>
          <p>{player.solitary && 'Solitaire'}</p>
          <p>{player.nbKill > 0 && `Nombre de Kills : ${player.nbKill}`}</p>
          {player.killersPlayer?.map((killer) => (
            <div className={PlayerStyle.killer} key={killerId++}>
              ⚔
              {killer ? (
                <PlayerHead
                  uuid={killer.uuid}
                  name={killer.name}
                  head={killer.head}
                />
              ) : (
                'PVE'
              )}
            </div>
          ))}
          {player.loversPlayer
            ?.filter((lover) => lover !== undefined)
            .map((lover) => (
              <div className={PlayerStyle.lover} key={loverId++}>
                ♥
                <PlayerHead
                  uuid={lover.uuid}
                  name={lover.name}
                  head={lover.head}
                />
              </div>
            ))}
          {player.amnesiacLoverPlayer && (
            <div className={PlayerStyle.lover}>
              Amnésique ♥
              <PlayerHead
                uuid={player.amnesiacLoverPlayer.uuid}
                name={player.amnesiacLoverPlayer.name}
                head={player.amnesiacLoverPlayer.head}
              />
            </div>
          )}
          {player.cursedLoverPlayer && (
            <div className={PlayerStyle.lover}>
              Maudit ♥
              <PlayerHead
                uuid={player.cursedLoverPlayer.uuid}
                name={player.cursedLoverPlayer.name}
                head={player.cursedLoverPlayer.head}
              />
            </div>
          )}
        </AccordionDetails>{' '}
      </Accordion>
    </Box>
  );
};

export default Player;
