import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Logo from '../img/Logo.png';
import MenuStyle from './Menu.module.css';
import { useNavigate } from 'react-router-dom';

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );

  const navigate = useNavigate();
  //<Button
  //  key={page.id}
  //  onClick={() => navigate(page.url)}
  //  sx={{ my: 2, color: 'white', display: 'block' }}
  //>
  //  {page.name}
  //</Button>

  const getButton = (
    color: string,
    text: string,
    onClick: () => void,
    id: number,
  ) => (
    <Button key={id} onClick={onClick} sx={{ my: 2, color, display: 'block' }}>
      {text}
    </Button>
  );

  const pages = [
    {
      id: 1,
      onClick: () => navigate('/'),
      text: 'Parties',
    },
    {
      id: 2,
      onClick: () => navigate('players'),
      text: 'Joueurs',
    },
    {
      id: 3,
      onClick: () => navigate('player'),
      text: 'Rechercher',
    },
  ];

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img src={Logo} className={MenuStyle.logo} />

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: {
                  xs: 'block',
                  md: 'none',
                  backgroundColor: 'primary',
                },
              }}
            >
              {pages.map((page) => (
                <div key={page.id} onClick={handleCloseNavMenu}>
                  {getButton('#123434', page.text, page.onClick, page.id)}
                </div>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            WereWolf UHC
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) =>
              getButton('white', page.text, page.onClick, page.id),
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
