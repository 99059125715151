export function getTextColor(id: number) {
  switch (id) {
    case 1:
      return '#155724';
    case 2:
      return '#856404';
    case 3:
      return '#1b1e21';
    case 4:
      return '#004085';
    case 5:
      return '#0c5460';
    case 6:
      return '#721c24';
    case 7:
      return '#818182';
    default:
      return '';
  }
}

export function getBackGroundColor(id: number) {
  switch (id) {
    case 1:
      return '#d4edda';
    case 2:
      return '#fff3cd';
    case 3:
      return '#d6d8d9';
    case 4:
      return '#cce5ff';
    case 5:
      return '#d1ecf1';
    case 6:
      return '#f8d7da';
    case 7:
      return '#fefefe';
    default:
      return '';
  }
}
