import { Button, Card, CardActions, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GameDto } from '../../games/dto/game.dto';
import { toTimeString } from '../../utils/time';
import PlayerViewStyle from './PlayerView.module.css';
import * as React from 'react';
type Props = { game: GameDto };

const PlayerView: React.FunctionComponent<Props> = ({ game }) => {
  const navigate = useNavigate();

  return (
    <Card className={PlayerViewStyle.card}>
      <CardContent>
        <h4>
          <b>{game.name}</b>
        </h4>
        <p>Nombre de Joueurs : {game.playersCount}</p>
        <p>Rôle : {game.players[0].roleTranslation}</p>
        {game.players[0].winner === true ? <p>Victoire ♕</p> : ''}
        <p>Durée : {toTimeString(game.duration)}</p>
        <p>Date : {new Date(game.date).toLocaleString('fr-FR')}</p>
      </CardContent>
      <CardActions>
        <Button
          onClick={() =>
            navigate(`/game-view/${game.gameUUID}`, { state: game })
          }
        >
          Détails Partie
        </Button>
      </CardActions>
    </Card>
  );
};

export default PlayerView;
