import PlayersAllStyle from './PlayersAll.module.css';

import * as React from 'react';
import { PlayerDto } from '../games/dto/player.dto';
import { Divider, Pagination } from '@mui/material';
import PlayerHead from '../utils/PlayerHead';

const PlayersAll: React.FunctionComponent<any> = () => {
  const [players, setPlayers] = React.useState<PlayerDto[]>([]);
  const [pageCount, setPageCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const itemsPerPage = 50;
  const itemOffset = ((page - 1) * itemsPerPage) % players.length;

  React.useEffect(() => {
    fetch(
      `https://api.ph1lou.fr/games/players/${
        (page - 1) * itemsPerPage
      }/${itemsPerPage}`,
    )
      .then((value) => {
        if (value.ok) {
          return value.json();
        }
        throw new Error('cc');
      })
      .then((players: PlayerDto[]) => {
        setPlayers(players);
      });
  }, [page, itemsPerPage]);

  React.useEffect(() => {
    fetch('https://api.ph1lou.fr/games/playersNumber')
      .then((value) => {
        if (value.ok) {
          return value.json();
        }
        throw new Error('cc');
      })
      .then((playersNumber) => {
        setPageCount(Math.ceil(playersNumber / itemsPerPage));
      });
  }, [itemsPerPage]);

  return (
    <div className={PlayersAllStyle.all_players}>
      <Divider>
        <p className={PlayersAllStyle.players_title}>Liste des joueurs</p>
      </Divider>
      {pageCount > 0 && (
        <Pagination
          count={pageCount}
          onChange={(_event, page) => setPage(page)}
          className={PlayersAllStyle.pagination}
        />
      )}
      <div className={PlayersAllStyle.players}>
        {players?.slice(itemOffset, itemOffset + itemsPerPage).map((player) => (
          <div key={player.uuid} className={PlayersAllStyle.player}>
            <PlayerHead
              uuid={player.uuid}
              name={player.name}
              head={player.head}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlayersAll;
