import {
  Button,
  createTheme,
  Divider,
  IconButton,
  Pagination,
  Snackbar,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import ResponsiveAppBar from '../menu/ResponsiveAppBar';
import PlayerInfo from '../players/PlayerInfo';
import { GameDto } from './dto/game.dto';
import Game from './Game';
import GamesStyle from './Games.module.css';
import GameView from './GameView';
import * as React from 'react';
import { frFR } from '@mui/x-data-grid';
import PlayersAll from '../players/PlayersAll';
import CloseIcon from '@mui/icons-material/Close';
import Footer from '../footer/Footer';

const Games: React.FunctionComponent<any> = () => {
  const [games, setGames] = React.useState<GameDto[]>([]);
  const [newGame, setNewGame] = React.useState<GameDto | undefined>();
  const [pageCount, setPageCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const itemsPerPage = 8;
  const [error, setError] = React.useState(false);
  const itemOffset = ((page - 1) * itemsPerPage) % games.length;
  const navigate = useNavigate();
  const theme = createTheme(
    {
      palette: {
        primary: { main: '#787a7cfa' },
        //  mode: 'dark',
      },
    },
    frFR,
  );

  const handleClose = () => setNewGame(undefined);

  const action = (
    <React.Fragment>
      <Button
        color="secondary"
        size="small"
        onClick={() => {
          if (newGame) {
            navigate(`/game-view/${newGame.gameUUID}`);
          }
          handleClose();
        }}
      >
        Voir
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  React.useEffect(() => {
    if (error) {
      return;
    }
    fetch(
      `https://api.ph1lou.fr/games/get/${
        (page - 1) * itemsPerPage
      }/${itemsPerPage}`,
      {
        mode: 'cors',
      },
    )
      .then((response) => {
        if (response.status != 200) {
          throw new Error('Problem');
        }
        return response.json();
      })
      .then((games: GameDto[]) => {
        setGames(games);
      })
      .catch(() => setError(true));
  }, [itemsPerPage, error, page]);

  React.useEffect(() => {
    if (error) {
      return;
    }
    fetch('https://api.ph1lou.fr/games/gamesnumber', {
      mode: 'cors',
    })
      .then((response) => {
        if (response.status != 200) {
          throw new Error('Problem');
        }
        return response.json();
      })
      .then((gamesNumber: number) => {
        setPageCount(Math.ceil(gamesNumber / itemsPerPage));
      })
      .catch(() => setError(true));
  }, [itemsPerPage, error]);

  React.useEffect(() => {
    const clientSocket = io('https://api.ph1lou.fr');
    clientSocket.on('new_game', (game: GameDto) => {
      setNewGame(game);
    });
  }, []);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <Snackbar
            open={Boolean(newGame)}
            action={action}
            onClose={handleClose}
            autoHideDuration={10000}
            message={'Une nouvelle partie a été publiée'}
          />
          <ResponsiveAppBar />
          <Routes>
            <Route path="game-view/:uuid" element={<GameView />} />
            <Route path="player-uuid/:playerUuid" element={<PlayerInfo />} />
            <Route path="player-name/:name" element={<PlayerInfo />} />
            <Route path="player" element={<PlayerInfo />} />
            <Route path="players" element={<PlayersAll />} />
            <Route
              path="/"
              element={
                <div>
                  <Divider>
                    <p className={GamesStyle.games_title}>Liste des parties</p>
                  </Divider>
                  {pageCount > 0 && (
                    <Pagination
                      count={pageCount}
                      onChange={(_event, page) => setPage(page)}
                    />
                  )}
                  <div className={GamesStyle.games}>
                    {error ? (
                      <p>Une Erreur s&apos;est produite</p>
                    ) : (
                      games
                        .slice(itemOffset, itemOffset + itemsPerPage)
                        .map((game) => <Game key={game.gameUUID} {...game} />)
                    )}
                  </div>
                </div>
              }
            />
          </Routes>
          <Footer />
        </StyledEngineProvider>
      </ThemeProvider>
    </div>
  );
};

export default Games;
