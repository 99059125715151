import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Details from './details/Details';
import { GameDto } from './dto/game.dto';
import GameViewStyle from './GameView.module.css';
import Players from './players/Players';
import * as React from 'react';

const GameView: React.FunctionComponent<any> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const game = location.state as GameDto;
  const param = useParams();
  if (!game && !param.uuid) {
    navigate('/');
  }

  return (
    <div>
      <main className={GameViewStyle.game_view}>
        <Details game={game} gameUuid={param.uuid || game.gameUUID} />
        <Players game={game} gameUuid={param.uuid || game.gameUUID} />
      </main>
    </div>
  );
};

export default GameView;
