import FooterStyle from './Footer.module.css';
import * as React from 'react';
import { Button, Divider } from '@mui/material';

const elements = [
  {
    id: 0,
    onClick: () => (location.href = 'https://utip.io/ph1lou'),
    text: 'Faire un Don',
  },
  {
    id: 1,
    onClick: () => (location.href = 'https://discord.com/invite/WucPqXuDwt'),
    text: 'Discord',
  },
  {
    id: 2,
    onClick: () =>
      (location.href =
        'https://cylenosheavenly.gitbook.io/doc-loup-garou-uhc-plugin-ph1lou/'),
    text: 'Règles LG UHC',
  },
  {
    id: 3,
    onClick: () => (location.href = 'https://doc-werewolfapi.ph1lou.fr/'),
    text: 'Documentation API',
  },
  {
    id: 4,
    onClick: () =>
      (location.href =
        'https://www.spigotmc.org/resources/loup-garou-uhc-werewolf-uhc.73113/'),
    text: 'Télécharger',
  },
];

const Footer: React.FunctionComponent<any> = () => {
  return (
    <footer className={FooterStyle.footer}>
      <Divider>
        <p className={FooterStyle.footer_title}>
          Site WereWolf UHC Statistiques
        </p>
      </Divider>
      <div className={FooterStyle.elements}>
        {elements.map((element) => {
          return (
            <Button
              key={element.id}
              onClick={element.onClick}
              sx={{ my: 2, color: 'primary', display: 'block' }}
            >
              {element.text}
            </Button>
          );
        })}
      </div>
    </footer>
  );
};

export default Footer;
