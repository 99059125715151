import { ReactElement } from 'react';
import { getBackGroundColor, getTextColor } from '../../utils/color';
import PlayerHead from '../../utils/PlayerHead';
import { toTimeString } from '../../utils/time';
import { PrettyEventDto } from '../dto/prettyevent.dto';
import DetailStyle from './Detail.module.css';
import * as React from 'react';

type Props = { event: PrettyEventDto };
const Detail: React.FunctionComponent<Props> = ({ event }) => {
  const text: Array<string> = event.text
    .split('{')
    .flatMap((text1) => text1.split('}'));
  const balises = new Array<ReactElement>();

  for (let i = 0; i < text.length; i++) {
    if (text[i].length > 1) {
      balises[i] = (
        <div key={i} className={DetailStyle.text}>
          {text[i]}
        </div>
      );
      continue;
    }

    try {
      const number = Number.parseInt(text[i]);
      balises[i] = (
        <PlayerHead
          key={i}
          uuid={event.players[number].uuid}
          name={event.players[number].name}
          head={event.players[number].head}
        />
      );
    } catch (error) {
      balises[i] = (
        <div key={i} className={DetailStyle.text}>
          {text[i]}
        </div>
      );
    }
  }
  return (
    <div
      className={DetailStyle.event}
      style={{
        color: getTextColor(event.color),
        backgroundColor: getBackGroundColor(event.color),
      }}
    >
      <p className={DetailStyle.timer}>{toTimeString(event.timer)}</p>
      {balises}
    </div>
  );
};

export default Detail;
