import { GameDto } from './dto/game.dto';
import StyleGame from './Game.module.css';
import { useNavigate } from 'react-router-dom';
import { toTimeString } from '../utils/time';
import Card from '@mui/material/Card';
import React from 'react';
import { Button, CardActions, CardContent } from '@mui/material';

const Game: React.FunctionComponent<GameDto> = (game: GameDto) => {
  const navigate = useNavigate();

  return (
    <Card className={StyleGame.card}>
      <CardContent>
        <h4>
          <b>{game.name}</b>
        </h4>
        <p>Nombre de Joueurs : {game.playersCount}</p>
        <p>Gagnant : {game.winnerCamp}</p>
        <p>Durée : {toTimeString(game.duration)}</p>
        <p>Date : {new Date(game.date).toLocaleString('fr-FR')}</p>
      </CardContent>
      <CardActions>
        <Button
          onClick={() =>
            navigate(`/game-view/${game.gameUUID}`, { state: game })
          }
        >
          Détails Partie
        </Button>
      </CardActions>
    </Card>
  );
};

export default Game;
